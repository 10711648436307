import React from "react"
import PostCard from "./PostCard"

const PostCardsList = ({ posts }) => {
    if (posts === undefined || posts.length === 0) return;

    return (
        <div className="block mx-auto sm:flex sm:flex-wrap justify-start">
            {posts.map(post => {
                return (
                    <PostCard
                        post={post}
                        key={post.fields.slug}
                        className="w-full md:w-1/2 xl:w-1/3"
                    />)
            })}
        </div>
    )
}

export default PostCardsList
